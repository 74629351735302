<template>
  <div class="login-card-box">
    <el-card class="login-card">
      <div class="logo-box">
        <div class="logo"></div>
<!--        <div class="title">Регистрация</div>-->
      </div>
      <div style="margin: 20px;"></div>
      <el-form label-position="top" :rules="rules" @submit.native.prevent label-width="80px" :model="form"  ref="ruleForm" >
        <el-form-item label="Логин" prop="login">
          <el-input v-model="form.login"></el-input>
        </el-form-item>
        <el-form-item label="Пароль" prop="password">
          <el-input v-model="form.password" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-button class="el-button--full-width" type="primary" native-type="submit" @click="onSubmit('ruleForm')">
          Войти
          <i class="el-icon-arrow-right" style="font-weight: bold"/>
        </el-button>
        <div class="additional-link">
          У вас нет аккаунта? <router-link to="/registration">Зарегистрироваться</router-link>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import authorizationAPI from '../modules/api/authorization'

function validation (type) {
  return (rule, value, callback) => {
    if (value === '') {
      callback(new Error(`Введите ${type === 'login' ? 'логин' : 'пароль'}`))
    } else if (value.length < 3) {
      callback(new Error(`Длина ${type === 'login' ? 'логина' : 'пароля'} должна состоять более чем из 3 символов`))
    } else {
      callback()
    }
  }
}

export default {
  data () {
    return {
      isLoading: false,
      form: {
        login: '',
        password: ''
      },
      rules: {
        login: [{ validator: validation('login'), trigger: 'blur' }],
        password: [{ validator: validation('password'), trigger: 'blur' }]
      }
    }
  },
  created () {
    this.logOut()
  },
  methods: {
    onSubmit (formName) {
      this.isLoading = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          authorizationAPI.logIn(this.form.login, this.form.password).then(resp => {
            console.log(resp)
          }).catch(err => {
            console.log(err)
            this.$notify.error('Ошибка авторизации', err.message)
          }).finally(() => {
            this.isLoading = false
          })
        } else {
          return false
        }
      })
    },
    logOut () {
      authorizationAPI.logOut()
    }
  }
}
</script>

<style scoped>
.login-card-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-card {
  width: 450px;
  padding: 20px;
}

.logo-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 80px;
  width: 100px;
  background: url('../assets/logo.svg') no-repeat center center;
  background-size: contain;
  margin-right: 10px;
}

.title {
  color: #5F6266;
  text-transform: uppercase;
  font-size: 22px;
}

.additional-link {
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
}

.additional-link a {
  text-decoration: none;
  color: #10569a;
}
</style>
